import React from "react"
import { Map, Form } from "./components"

export const ContactScreen = () => (
  <>
    <div id="contact" className="contact block-section image-block bg-white">
      <Form />
      <Map />
    </div>
  </>
)
